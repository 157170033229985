<template>
    <div class="newsBox">
        <div class="details">


            <div class="detailContent">
                <div class="detailTitle">{{ newsWenList.title }}</div>
                <div class="dataS">发布时间:{{ createTime }}</div>

                <div class="Cont"> {{ newsWenList.content }} </div>
            </div>
        </div>

        <Footer_Compontent></Footer_Compontent>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
import Footer_Compontent from '../../components/Footer_Compontent.vue';
export default {
    name: "NewsCenterDetail",
    components: { Footer_Compontent },
    data() {
        return {
            id: this.$route.query.id,
            newsWenList: {},
            createTime: '', //时间
        }
    },
    created() {
        axios.get(`${baseUrl}/basic/newsDetail`, {
            params: { id: this.id }
        })
            .then(res => {
                // console.log(res.data.result);
                this.newsWenList = res.data.result;
                this.createTime = res.data.result.createTime.split(' ')[0]
                // console.log(this.newsWenList);

                let reg = new RegExp('<[^<>]+>', 'g')
                var textList = this.newsWenList;

                this.newsWenList.content = textList.content.replace(reg, "");
                this.newsWenList.content = this.newsWenList.content.replace(/&ldquo;/ig, "");
                this.newsWenList.content = this.newsWenList.content.replace(/&middot;/ig, "");
                this.newsWenList.content = this.newsWenList.content.replace(/&rdquo;/ig, "");
                this.newsWenList.content = this.newsWenList.content.replace(/&nbsp;/ig, "");
                this.newsWenList.content = this.newsWenList.content.replace(/&Aacute;/ig, "");

            })
            .catch(err => {
                console.error(err);
            })
    }
}
</script>

<style lang="scss" scoped>
.newsBox {
    width: 100%;
    height: 100%;

    .details {
        width: 1200px;
        margin: 0 auto;

        .detailContent {
            width: 1200px;
            margin: 100px auto;

            .detailTitle {
                font-size: 24px;
                font-weight: bold;
                color: #333333;
                text-align: center;
            }

            .dataS {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                margin-top: 16px;
                text-align: center;
            }

            .Cont {

                font-size: 16px;
                font-weight: 400;
                color: #333333;
                margin-top: 32px;
                line-height: 30px;
            }
        }
    }
}
</style>